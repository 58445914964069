var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 font-Noto-Sans user-list-page"},[_c('v-sheet',{attrs:{"color":"transparent"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"\n            fw-500\n            heading-text\n            d-flex\n            justify-space-between\n            flex-wrap\n            align-center\n            mt-5\n            mb-2\n          "},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('h2',{staticClass:"font-24px mb-1 mr-7"},[_c('span',{staticClass:"fw-500"},[_vm._v("ユーザー管理")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"font-20px fw-400"},[_vm._v("ユーザー一覧")])]),_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.type,staticClass:"d-flex font-14px mr-7 align-center cursor-pointer",class:tab.selected ? 'text-red' : '',on:{"click":function($event){return _vm.filterTable(tab.type)}}},[_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(tab.text)+_vm._s(tab.showCount ? '(' + tab.count + ')' : ''))])])})],2),_c('div',{staticClass:"d-flex font-14px align-center"},[_c('v-btn',{staticClass:"white text-red border-red",attrs:{"min-width":"188px"},on:{"click":function($event){_vm.toggleSearch = !_vm.toggleSearch
                _vm.setChangedInputType('nationalities')}}},[_vm._v("詳細条件検索")])],1)])]),(_vm.toggleSearch)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('SearchArea',{attrs:{"toggleSearch":_vm.toggleSearch,"searchFields":_vm.searchFields,"selectTypeOptions":_vm.selectTypeOptions},on:{"toggleSearch":function($event){return _vm.setToggleSearch($event)},"searchSubmit":function($event){return _vm.searchSubmit($event)},"changedInputType":function($event){return _vm.setChangedInputType($event)},"resetForm":function($event){return _vm.getDataFromApi()}}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex w-100",attrs:{"cols":"12","md":"12"}},[_c('DataTable',{attrs:{"items":_vm.initialLoad ? [] : _vm.getAllUser.users,"headers":_vm.headers,"total-records":_vm.getUserTotalRecord ? _vm.getUserTotalRecord : 0,"number-of-pages":_vm.getUserTotalPages ? _vm.getUserTotalPages : 0,"loading":_vm.loading},on:{"click:row":function($event){return _vm.$router.push({ name: 'UserDetails', params: { id: $event.id } })},"update:options":_vm.updateTable},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formatTime(item.created_at))+" ")])]}},{key:"item.last_login_date",fn:function(ref){
                var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formatDate(item.last_login_date)))]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formatTime(item.last_login_date))+" ")])]}},{key:"item.user_name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-1"},[_c('v-img',{staticClass:"rounded-circle",attrs:{"src":item.face_picture_path,"max-height":"49","height":"49","max-width":"49","width":"49","lazy":""}}),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(item.user_name))])],1)]}},{key:"item.nationality",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nationality.ja))])]}},{key:"item.gender",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item.gender.ja)))])]}},{key:"item.applied_count",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.applied_count ? item.applied_count : '-'))])]}},{key:"item.matched_count",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.matched_count ? item.matched_count : '-'))])]}},{key:"item.cancel_count",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.cancel_count ? item.cancel_count : '-'))])]}},{key:"item.visa_type",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.visa_type.ja))])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"\n                font-12px\n                px-3\n                py-1\n                fw-500\n                rounded-xl\n                no-background-hover\n              ",class:[item.status == 'Rejected' ? 'white--text' : ''],attrs:{"color":item.status == 'Rejected'
                  ? '#E87C64'
                  : item.status == 'Awaiting'
                  ? '#FFF7C0'
                  : '#AAEFA7',"height":"22","min-width":"72","elevation":"0","ripple":false}},[_vm._v(" "+_vm._s(item.status == 'Awaiting' && item.is_profile_updated == '1' ? _vm.$t('awaiting_status_two') : _vm.$t(item.status))+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }